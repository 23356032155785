import { Routes, Route } from "react-router-dom";
import { Home } from "../Screens/Public/Home";
import { WhatWeDo } from "../Screens/Public/WhatWeDo";
import { HowWorks } from "../Screens/Public/HowWorks";
import { Affiliate } from "../Screens/Public/Affiliate";
import { Partner } from "../Screens/Public/Partner";
import { Questions } from "../Screens/Public/Questions";
import { Register } from "../Screens/Public/Register";
import { Login } from "../Screens/Public/Login";
import { Blog } from "../Screens/Public/Blog";
import { BlogNews } from "../Screens/Public/Blog/blogNews";

/*******************************Private*******************************/
import { Dashboard } from "../Screens/Private/Dashboard";
import { ApplicationPortal } from "../Screens/Private/ApplicationPortal";
import { PersonalInformation } from "../Screens/Private/PersonalInformation";
import { EmergencyContact } from "../Screens/Private/EmergencyContact";
import { SchoolRecords } from "../Screens/Private/SchoolRecords";
import { EnglishProficiency } from "../Screens/Private/EnglishProficiency";
import { AdditionalDocuments } from "../Screens/Private/AdditionalDocuments";
import { Awards } from "../Screens/Private/Awards";
import { ReviewComplete } from "../Screens/Private/ReviewComplete";
import { TermsConditions } from "../Screens/Private/TermsConditions";

export function Router() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/o-que-fazemos" element={<WhatWeDo />} />
      <Route path="/como-funciona" element={<HowWorks />} />
      <Route path="/seja-um-afiliado" element={<Affiliate />} />
      <Route path="/seja-um-parceiro" element={<Partner />} />
      <Route path="/perguntas" element={<Questions />} />
      <Route path="/cadastre-se" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/blog/noticias/:id" element={<BlogNews />} />

      {/*******************************Private*******************************/}
      <Route path="/boas-vindas" element={<Dashboard />} />
      <Route path="/informacao-programa" element={<ApplicationPortal />} />
      <Route path="/informacoes-pessoais" element={<PersonalInformation />} />
      <Route path="/contato-emergencia" element={<EmergencyContact />} />
      <Route path="/historico-escolar" element={<SchoolRecords />} />
      <Route path="/proficiencia-ingles" element={<EnglishProficiency />} />
      <Route path="/documentos-adicionais" element={<AdditionalDocuments />} />
      <Route path="/premiacoes" element={<Awards />} />
      <Route path="/revisar-concluir" element={<ReviewComplete />} />
      <Route path="/termos-condicoes" element={<TermsConditions />} />
    </Routes>
  );
}
