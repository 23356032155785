import axios from "axios";
import moment from 'moment';
import 'moment/locale/pt-br';
import { useEffect, useState } from "react";
import "react-alice-carousel/lib/alice-carousel.css";
import { useNavigate } from "react-router-dom";
import Image31 from "../../../Assets/images/icon/image31.png";
import Image32 from "../../../Assets/images/icon/image32.png";
import { Button } from "../../../Components/Button";
import { Footer } from "../../../Components/Public/Footer";
import { Header } from "../../../Components/Public/Header";
import styles from "../Blog/blog.module.scss";
import { getSelectedLanguage } from "../../../Components/Public/Header/language";
import { useTranslation } from "react-i18next";

export function Blog() {
  moment.locale('pt-br');
  const navigate = useNavigate();
  const { t } = useTranslation()

  // useEffect(() => {
  //   window.location.href = '/'
  //   window.scroll(0, 1);
  // }, []);

  function handleNewsBlog(id: any) {
    navigate(`/blog/noticias/${id}`);
  }

  

  useEffect(() => {
    loadData(1)
  }, [])

  const [limit, setLimit] = useState(12)
  const [articles, setArticles] = useState([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(true)

  const loadData = async (page: any) => {
    const offset = page === 1 ? 0 : page - 1 * (limit)
    const lang = getSelectedLanguage()
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/v1/articles-site/?limit=${limit}&offset=${offset}&language=${lang === "es"? 'es-ES' : lang} 	`)

    if (res?.data && res?.status === 200) {
      setArticles(res.data.results)
      setTotal(res.data.count)
      setLoading(false)
    }
  }

  const getCountComments = (item: any) => {
    return Array.isArray(item.comments) ? item.comments.length : 0
  }

  const getCountLike = (item: any) => {
    return Array.isArray(item.comments) ? item.comments.filter((c: any) => c.like).length : 0
  }

  return (
    <div id="page-top" className={styles.generalContainer}>
      <Header position={5} 
        changeLanguage={() => {
          setLoading(true)
          loadData(1)
        }}
      />
      <header className={`masthead ${styles.division01}`}>
        <div className={`container px-5`}>
          <div className="row gx-5 align-items-center">
            <span className={styles.titlePage}>{t('blog.title')}</span>
            <div className="d-flex">
              <div className="col-lg-6">
                <span className={styles.textImage}>{t('blog.title2')}</span>

                {
                  loading ?
                  <>  
                    <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: 200, gap: 14}}>
                      <div className="spinner-border text-light" role="status"/>
                      <span className="sr-only text-light">{t('blog.loading')}</span>
                    </div>
                  </>
                  :
                  <>
                    {articles && articles.length > 0 ? articles.slice(0, 1).map((item: any, index: any) =>
                        <div key={index} className={`${styles.emphasis}`} onClick={() => handleNewsBlog(item.id)}>
                          <img src={item?.image} alt="Imagem" style={{ width: '80%', height: '250px', objectFit: 'cover' }} />
                          <div className={styles.rowColumn}>
                            <div>
                              {item?.title}
                            </div>
                            <span>{item?.creator?.name ? `Por ${item?.creator?.name}` : ''}</span>
                          </div>
                        </div>
                      ) : 
                      <>
                        <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: 200, gap: 14}}>
                          <span className="sr-only text-light">{t('blog.noPosts')}</span>
                        </div>
                      </>
                    }
                  </>
                }            
              </div>


              <div className="col-lg-6">
                <span className={styles.textImage}>{t('blog.title3')}</span>
                <div className={styles.morePopular}>

                  {
                    loading ?
                    <>  
                      <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: 200, gap: 14}}>
                        <div className="spinner-border text-light" role="status"/>
                        <span className="sr-only text-light">{t('blog.loading')}</span>
                      </div>
                    </>
                    :
                    <>
                      {
                        articles && articles.length > 1 ? articles.slice(1, 4).map((item: any, index: any) =>
                        <div key={index} className={styles.rowContent} onClick={() => handleNewsBlog(item.id)}>
                          <img src={item?.image} alt="Imagem" style={{ objectFit: 'cover' }} />
                          <div className={styles.rowColumn}>
                            <div>
                              {item?.title}
                            </div>
                            <span>{item?.creator?.name ? `Por ${item?.creator?.name}` : ''}</span>
                          </div>
                        </div>
                        ) :
                        <>
                          <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: 200, gap: 14}}>
                            <span className="sr-only text-light">{t('blog.noPosts')}</span>
                          </div>
                        </>
                        }
                    </>
                  }       

                  

                  {/* <div className={styles.rowContent} onClick={handleNewsBlog}>
                    <img src={Image15} alt="Imagem" />
                    <div className={styles.rowColumn}>
                      <div>
                        Consulado Canadense participa de webinar com a Canadá
                        Sem Fronteiras
                      </div>
                      <span>Por Tati Pinheiro</span>
                    </div>
                  </div>
                  <div className={styles.rowContent} onClick={handleNewsBlog}>
                    <img src={Image15} alt="Imagem" />
                    <div className={styles.rowColumn}>
                      <div>
                        Consulado Canadense participa de webinar com a Canadá
                        Sem Fronteiras
                      </div>
                      <span>Por Tati Pinheiro</span>
                    </div>
                  </div>
                  <div className={styles.rowContent} onClick={handleNewsBlog}>
                    <img src={Image15} alt="Imagem" />
                    <div className={styles.rowColumn}>
                      <div>
                        Consulado Canadense participa de webinar com a Canadá
                        Sem Fronteiras
                      </div>
                      <span>Por Tati Pinheiro</span>
                    </div>
                  </div> */}

                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* ************************************************************************* */}

      <section className={styles.division02}>
        <div className={`container px-5`}>
          <div className="row gx-1 align-items-start">
            <div className={styles.title02Main}>{t('blog.title')}</div>

            <form
              className={`row gx-1 align-items-center ${styles.formBtnSearch}`}
            >
              <div className={`col-sm-5 ${styles.groupInputBtn}`}>
                <div className="input-group">
                  <input
                    type="text"
                    className={`form-control ${styles.textInpuSearch} ${styles.subTextInpuSearch}`}
                    id="inlineFormInputGroupUsername"
                    placeholder={t('blog.placeholder')}
                  />
                </div>
                <div className={`col-sm-1 ${styles.btnSearchRet}`} />
                <div className={`col-sm-3 ${styles.btnSearch}`}>
                  <Button name={t('blog.btnBuscar')} typeButton="primary" />
                </div>
              </div>
            </form>

            <div className="row row-cols-3 g-4">

              {articles && articles.length > 0 ? articles.map((item: any, index: any) =>
                <div
                  key={index}
                  className={`col ${styles.mouseHouver}`}
                  onClick={() => handleNewsBlog(item.id)}
                  // style={{margin: 8}}
                >
                  <div className="card h-100">
                    <img
                      src={item?.image}
                      className="card-img-top"
                      alt="Imagem"
                      style={{ objectFit: 'cover' }}
                    />
                    <img
                      src={Image31}
                      alt="Imagem"
                      className={styles.imageMessage}
                    />
                    <div className={styles.circleMessage}>{getCountComments(item)}</div>
                    <img
                      src={Image32}
                      alt="Imagem"
                      className={styles.imageHeart}
                    />
                    <div className={styles.circleHeart}>{getCountLike(item)}</div>
                    <div className="card-body">
                      <div className={styles.titleCard}>
                        {item?.title}
                      </div>
                      <div className={styles.nameAuthor}>
                        Por <span>{item?.creator?.name}</span>
                      </div>
                      <div className={styles.dateCard}>{moment(item?.updated_at).format('LL')}</div>
                      <div className={styles.descCard}>
                        {item?.subtitle}
                      </div>
                    </div>
                  </div>
                </div>
              ) : 
                <>
                  <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: 200, gap: 14}}>
                    <span className="sr-only">{t('blog.noPosts')}</span>
                  </div>
                </>
              }     

            </div>
          </div>
          {/* <div className={styles.btnLoadMore}>
            <Button name="Carregar mais" typeButton="primary" />
          </div> */}
        </div>
      </section>

      {/* ************************************************************************* */}

      <section className={styles.division03}>
        <div className={`masthead ${styles.division03ColorTop}`}>
          <div className="container px-5">
            <div className={`row gx-1 ${styles.containerFoot}`}>
              <div className="col-lg-6">
                <span className={styles.titleFoot}>
                  {t('blog.title4')}
                </span>
                <span className={styles.subTitleFoot}>
                {t('blog.title5')}
                </span>
              </div>
              <div className="col-lg-6">
                <form className={`row g-3`}>
                  <div className="col-md-12">
                    <label
                      form="validationDefault02"
                      className={`form-label ${styles.textLabel}`}
                    >
                      {t('blog.input1')}<span className={styles.asterisk}>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${styles.textInput}`}
                      id="validationDefault02"
                      placeholder={t('blog.input1Placeholder')}
                      value=""
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <label
                      form="validationDefault02"
                      className={`form-label ${styles.textLabel}`}
                    >
                      {t('blog.input2')}<span className={styles.asterisk}>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${styles.textInput}`}
                      id="validationDefault02"
                      placeholder={t('blog.input2Placeholder')}
                      value=""
                      required
                    />
                    <div className={`col-md-12 form-check ${styles.checkForm}`}>
                      <input
                        className={`form-check-input ${styles.formCheck}`}
                        type="checkbox"
                        value=""
                        id="flexCheckChecked"
                        checked
                      />
                      <label
                        className={`form-check-label ${styles.textCheck}`}
                        htmlFor="flexCheckChecked"
                      >
                        {t('blog.checkbox')}
                      </label>
                    </div>
                  </div>
                  <br />
                  <div className={styles.section05}>
                    <Button
                      name={t('blog.btnSend')}
                      // onClick={handleLogin}
                      typeButton="primary"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
